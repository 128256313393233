.max-width {
  position: relative;

  width: 100%;
  max-width: calc(
    var(--page-max-width) + var(--page-left-padding) + var(--page-right-padding)
  );

  margin-right: auto;
  margin-left: auto;
  padding-right: var(--page-right-padding);
  padding-left: var(--page-left-padding);
}
