.language-switcher {
  position: relative;

  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 16px;

  color: var(--white);

  cursor: pointer;
}

.language-switcher__current {
  position: relative;

  display: flex;
  align-items: center;

  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
}

.language-switcher__icon {
  margin-left: 8px;
}

.language-switcher__dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: var(--z-index-header);

  display: block;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 70px;

  border-radius: 0 0 2px 2px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  list-style: none;
}

.language-switcher__dropdown a {
  display: block;
  width: 100%;
  padding: 12px 12px;

  font-family: var(--font-family-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  color: var(--black);

  text-decoration: none;

  text-align: center;
  transition: 200ms background-color;
}
.language-switcher__dropdown a:hover {
  text-decoration: underline;
}
