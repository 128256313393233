.header {
  --color: var(--black);
  --logo-width: 113px;
  --logo-height: 37px;
  --background-color: var(--white);
}

.header__background,
.header__body {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;

  height: var(--header-height);
}

.header__background {
  z-index: var(--z-index-header-background);

  background-color: var(--background-color);
}

.header__body {
  z-index: var(--z-index-header);

  flex-direction: column;
  justify-content: center;
  height: var(--header-height);
}

.sup-header {
  --color: var(--white);
  --background-color: var(--primary-500);

  position: relative;
  z-index: calc(var(--z-index-header) + 1);

  height: 40px;

  color: var(--color);

  background-color: var(--background-color);
}
@media (--header-breakpoint) {
  .sup-header {
    display: none;
  }
}

.sub-header {
  height: 100%;

  height: calc(var(--header-height) - 40px);

  background-color: var(--background-color);
}
@media (--header-breakpoint) {
  .sub-header {
    height: 100%;

    box-shadow: 0 5px 6px 0 rgba(0, 0, 0, 0.1);
  }
}

.sup-header .max-width,
.sub-header .max-width {
  position: relative;

  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
}
.sup-header .max-width {
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-right: calc(var(--page-right-padding) + 80px);
}

.sup-header__wrap {
  display: flex;
  align-items: center;
  gap: 72px;
  height: 100%;
}

.sub-header .max-width {
  justify-content: space-between;
}

.sup-header__social {
  position: relative;
  z-index: calc(var(--z-index-header) + 1);

  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;
  list-style: none;
}

.sub-header__logo {
  display: flex;
  align-items: center;
  height: 100%;

  -webkit-tap-highlight-color: transparent;
}

@media (--header-breakpoint) {
  .sub-header__logo {
    margin: auto;
  }
}

.sub-header__logo svg {
  display: block;
  align-items: center;
  width: var(--logo-width);
  height: var(--logo-height);
  user-select: none;
}

.sub-header__right {
  --gap: 50px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}
@media (--tablet) {
  .sub-header__right {
    --gap: 28px;
  }
}

.sub-header__links {
  display: flex;
  height: 100%;
}
@media (--header-breakpoint) {
  .sub-header__links {
    display: none;
  }
}

.sub-header__link {
  --offset: -32px;

  position: relative;

  display: flex;
  align-items: center;
  height: 100%;
  perspective: 800px;
  perspective-origin: 50% 0;
}
.sub-header__link--has-sub-links.sub-header__link--active {
  z-index: 1;
}

.sub-header__link__main {
  --padding-left: calc(var(--gap) / 2);
  --padding-right: calc(var(--gap) / 2);

  position: relative;

  display: flex;
  align-items: center;
  height: 100%;
  padding-right: var(--padding-right);
  padding-left: var(--padding-left);

  font-size: var(--font-size-body);
  font-weight: 500;
  line-height: 1.5;
  color: var(--color);
  text-decoration: none;
  cursor: default;

  transition: text-decoration-color 200ms;
}
a.sub-header__link__main {
  cursor: pointer;
}
.sub-header__link:last-of-type .sub-header__link__main {
  padding-right: var(--gap);
}

/* .sub-header__link__main::after {
  --scale: 0.2;

  position: absolute;
  top: 50%;
  right: var(--padding-right);
  left: var(--padding-left);

  content: '';
  display: block;

  border-bottom: 1px solid var(--primary-600);

  transform-origin: left;
  transform: translateY(12px) scaleX(var(--scale));
  opacity: 0;
  transition:
    200ms opacity,
    200ms transform;
}
.sub-header__link__main--current::after,
.sub-header__link__main:hover::after {
  --scale: 1;

  opacity: 1;
} */

.sub-header__link__main--current {
  color: var(--primary-600);
}

.sub-header__link__main__icon {
  --size: 14px;

  width: var(--size);
  height: var(--size);
  margin-left: 8px;
  object-fit: contain;
  user-select: none;
  transition: 200ms transform;
}
.sub-header__link--active .sub-header__link__main__icon {
  transform: rotate(180deg);
}

.sub-header__cta {
  display: flex;
}

@media (--header-breakpoint) {
  .sub-header__cta {
    display: none;
  }
}
