.text,
.group--theme-black .text {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
.group--theme-white .text,
.group--theme-grey .text {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

.text:first-child {
  margin-top: var(--section-gap);
}
.layout__body > .text:first-child {
  margin-top: calc(var(--header-height) + var(--section-gap));
}
* + .text {
  margin-top: var(--section-gap);
}

.text__title {
  font-family: var(--font-family-title);
  font-size: var(--font-size-56);
  font-weight: 400;
  line-height: 140%;
  color: var(--color);
}
@media (--tablet) {
  .text__title {
    font-size: var(--font-size-40);
  }
}
@media (--mobile) {
  .text__title {
    font-size: 32px;
  }
  .text__title br {
    display: none;
  }
}

.text__body {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
}
.text--layout-three-quarters .text__body {
  max-width: 820px;
}
.text--layout-grid .text__body {
  grid-template-columns: repeat(2, 1fr);
  column-gap: 48px;
}
* + .text__body {
  margin-top: 72px;
}
@media (--tablet) {
  .text--layout-grid .text__body {
    column-gap: 24px;
  }
  * + .text__body {
    margin-top: 28px;
  }
}
@media (--mobile) {
  .text--layout-grid .text__body {
    grid-template-columns: 1fr;
  }
  * + .text__body {
    margin-top: 20px;
  }
}

.text__content {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 500;
  line-height: 170%;
  letter-spacing: 0.02em;
  color: rgba(var(--color-rgb), 0.8);
}

* + .text__content {
  margin-top: 72px;
}

.text__content a {
  color: inherit;
  text-decoration: none;
}
.text__content a:hover {
  text-decoration: underline;
}

.text__content * + p {
  margin-top: 24px;
}

.text__content ul {
  padding-left: 24px;
}
.text__content * + ul {
  margin-top: 24px;
}

.text__content ul li + li {
  margin-top: 8px;
}

.text__content h2 {
  font-family: var(--font-family-text);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  color: var(--color);
}

.text__content h3 {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: rgba(var(--color-rgb), 0.5);
  text-transform: uppercase;
}
