.mobile-menu {
  --button-height: 20px;
  --color: var(--black);
  --logo-width: 113px;
  --logo-height: 37px;

  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;

  display: none;

  max-height: 100vh;
  margin-left: auto;

  background-color: var(--white);

  will-change: clip-path;

  user-select: none;
}

.header--theme-black + .mobile-menu {
  --color: var(--black);
}
@media (--header-breakpoint) {
  .mobile-menu {
    display: block;
  }
}

.mobile-menu__button {
  --transition-duration: 300ms;
  --border-radius: 50px;

  position: fixed;
  top: calc((var(--header-height) - var(--button-height)) / 2);
  right: var(--page-right-padding);
  z-index: var(--z-index-mobile-menu-button);

  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--button-height);
  height: var(--button-height);
}

.mobile-menu--open .mobile-menu__button {
  background-color: var(--white);
}

.mobile-menu__button__burger {
  --size: 24px;

  position: absolute;
  top: 50%;
  left: 50%;

  width: var(--size);
  height: var(--size);
  object-fit: contain;

  opacity: 1;
  pointer-events: none;
  transform-origin: center center;
  transition:
    opacity var(--transition-duration),
    transform var(--transition-duration);
  transform: scale(1) translate(-50%, -50%);
}

.mobile-menu--open .mobile-menu__button__burger {
  opacity: 0;
  transform: scale(0.7);
}

.mobile-menu__button__cross {
  --size: 24px;

  position: absolute;
  top: 50%;
  left: 50%;

  width: var(--size);
  height: var(--size);
  object-fit: contain;

  opacity: 0;
  pointer-events: none;
  transition:
    opacity var(--transition-duration),
    transform var(--transition-duration);
  transform: scale(0.5) translate(-50%, -50%);
}

.mobile-menu--open .mobile-menu__button__cross {
  opacity: 1;
  transform: scale(1) translate(-50%, -50%);
}

.mobile-menu__top {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 20px 0;
}
.mobile-menu__title {
  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
  letter-spacing: 0.05em;
  color: rgba(var(--black-rgb), 0.8);
  text-transform: uppercase;

  opacity: 0;
}

.mobile-menu--open .mobile-menu__title {
  opacity: 1;
}

.mobile-menu__title + .mobile-menu__links {
  margin-top: 24px;
}
.mobile-menu__links {
  position: fixed;
  top: 0;

  z-index: 999;

  display: flex;
  flex-direction: column;

  justify-content: space-between;

  overflow-y: auto;

  width: 100%;
  height: 100%;

  padding: 0 24px 24px;

  background-color: var(--white);
  -webkit-overflow-scrolling: touch;

  pointer-events: none;
  opacity: 0;
}

.mobile-menu--open .mobile-menu__links {
  pointer-events: auto;
  opacity: 1;
}

.mobile-menu__links__header__wrap {
  --logo-height: 37px;

  position: relative;

  z-index: var(--z-index-header);

  justify-content: center;
  height: var(--header-height);

  pointer-events: none;
}

.mobile-menu__logo {
  position: absolute;
  left: 50%;

  display: flex;
  align-items: center;
  width: fit-content;
  height: 100%;

  color: black;
  transform: translateX(-50%);
  pointer-events: auto;
}

.mobile-menu__logo svg {
  display: block;
  align-items: center;
  width: auto;
  width: var(--logo-width);
  height: var(--logo-height);
  user-select: none;
}

.mobile-menu--open .mobile-menu__logo {
  display: flex;
}

.mobile-menu__links .mobile-menu__list {
  display: block;
}

.mobile-menu__links .mobile-menu__list .mobile-menu__item {
  position: relative;

  display: flex;

  flex-direction: column;
  align-items: center;
}

.mobile-menu__links .mobile-menu__list .mobile-menu__item:not(:last-child) {
  border-bottom: 1px solid rgba(var(--black-rgb), 0.2);
}

.mobile-menu__link__title {
  display: flex;
  align-items: center;
}

.mobile-menu__link__title span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  margin-left: 8px;
  padding: 8px;

  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.05em;
  color: var(--black);

  border-radius: 75px;

  background-color: var(--primary-500);
}

.mobile-menu__link {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: 20px 0;

  font-family: var(--font-family-text);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: var(--black);
  text-decoration: none;
  text-align: center;
}
.mobile-menu__link:not(.mobile-menu__link--has-sub-links)::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
  -webkit-tap-highlight-color: rgb(var(--black-rgb) 0.06);
}

.mobile-menu__link--has-sub-links .mobile-menu__link__icon {
  transform: rotate(90deg);

  transition: transform 300ms;
}

.mobile-menu__link--open
  .mobile-menu__link--has-sub-links
  .mobile-menu__link__icon {
  transform: rotate(-90deg);
}

/* subLinks */

.mobile-menu__sub-links {
  display: none;

  -webkit-overflow-scrolling: touch;
  pointer-events: none;
}

.mobile-menu__link--open .mobile-menu__sub-links {
  display: flex;
  flex-direction: column;
  gap: 32px;

  height: auto;

  margin-left: 16px;
  padding: 12px 0 32px 0;

  align-self: baseline;
  list-style: none;
  pointer-events: auto;
}

.mobile-menu__sub-link {
  display: flex;

  align-items: center;
}
.mobile-menu__sub-link__link {
  text-decoration: none;
}

.mobile-menu__sub-link__icon {
  flex-shrink: 0;
  width: 29px;
  height: 29px;
}

.mobile-menu__sub-link__icon img {
  width: 100%;
  height: 100%;

  object-fit: contain;
}

.mobile-menu__sub-link__title {
  font-family: var(--font-family-text);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: 0.05em;
  color: var(--white);
}

.mobile-menu__sub-link__icon + .mobile-menu__sub-link__link {
  margin-left: 18px;
}
