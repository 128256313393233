.layout {
  min-height: 100vh;

  background-color: var(--white);
}
.layout--theme-grey-100 {
  background-color: var(--grey-100);
}

* + .layout__footer {
  margin-top: var(--section-gap);
}
.group + .layout__footer {
  margin-top: 0;
}
