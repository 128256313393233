.button {
  --height: fit-content;
  --min-height: var(--height);
  --horizontal-padding: 0;
  --text-horizontal-padding: 0;
  --gap: 16px;
  --vertical-padding: 0;
  --background-color: transparent;
  --color: var(--black);
  --border-radius: 0;

  position: relative;

  display: inline-block;
  overflow: hidden;
  height: var(--height);
  min-height: var(--min-height);
  padding-right: var(--horizontal-padding);
  padding-left: var(--horizontal-padding);

  border-radius: var(--border-radius);
  outline: none;
  cursor: pointer;
  user-select: none;
  transition-duration: 450ms;
  transition-property: color;
}
.button--disabled,
.button--loading {
  cursor: default;
}

/** Themes **/

.group--theme-black .button:not(.button--theme-gold),
.group--theme-black .button:not(.button--theme-primary-white) {
  --color: var(--white);
}

.group--theme-black .button:not(.button--theme-gold):hover,
.group--theme-black .button:not(.button--theme-primary-white):hover {
  --color: var(--white);
}

.button--theme-white {
  --color: var(--white);
}

.button--theme-gold {
  --color: var(--gold-600) !important;
}

.button--theme-blue {
  --color: var(--white);
  --background-color: var(--primary-700);
}

.button--theme-primary-blue {
  --color: var(--white);
  --background-color: var(--primary-500);
  --horizontal-padding: 24px;
  --height: 42px;
}

.button--theme-primary-black {
  --color: var(--white);
  --background-color: var(--black);
  --height: 42px;
  --horizontal-padding: 48px;

  padding-right: 13px;
}

.button--theme-primary-white {
  --color: var(--dark) !important;
  --background-color: var(--white);
  --height: 45px;
  --horizontal-padding: 28px;

  width: 100%;
}

/** Height **/

.button--height-xsmall {
  --height: 35px;
  --horizontal-padding: 13px;
}

/** Width state **/

.button--width-full {
  --horizontal-padding: 48px;
  --height: 45px;
}

/** Hover state **/

.button:not(.button--disabled):hover {
  --color: var(--black);
}

.button--theme-white:not(.button--disabled):hover {
  --color: var(--white);
}

.button--theme-gold:not(.button--disabled):hover {
  --color: var(--gold-600);
}

.button--theme-blue:not(.button--disabled):hover {
  --color: var(--white);
}

.button--theme-primary-blue:not(.button--disabled):hover {
  --color: var(--white);
  --background-color: var(--primary-500);
}

.button--theme-primary-black:not(.button--disabled):hover {
  --color: var(--white);
  --background-color: var(--black);
}

.button--theme-primary-white:not(.button--disabled):hover {
  --color: var(--dark);
  --background-color: var(--white);
}

/** Active state **/

.button:not(.button--disabled):active {
  --color: var(--black);
}

.button--theme-white:not(.button--disabled):active {
  --color: var(--white);
}

.button--theme-gold:not(.button--disabled):active {
  --color: var(--gold-600);
}

.button--theme-blue:not(.button--disabled):active {
  --color: var(--white);
}

.button--theme-primary-blue:not(.button--disabled):active {
  --color: var(--white);
  --background-color: var(--primary-500);
}

.button--theme-primary-black:not(.button--disabled):active {
  --color: var(--white);
  --background-color: var(--black);
}

.button--theme-primary-white:not(.button--disabled):active {
  --color: var(--dark);
  --background-color: var(--white);
}

/* Disabled state */

.button--theme-primary-blue.button--disabled {
  --background-color: rgba(var(--grey-rgb), 0.2);
  cursor: not-allowed;
  pointer-events: none;
}

/** Background **/

.button__background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  border-radius: var(--border-radius);

  background-color: var(--background-color);
  transition-duration: 200ms;
  transition-property: opacity, background-color;
}

.button--theme-primary-blue .button__background,
.button--theme-blue .button__background,
.button--theme-primary-black .button__background,
.button--theme-primary-white .button__background {
  --border-radius: 4px;
}

/** Content **/

.button__content {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: var(--height);
  min-height: var(--min-height);
}
.button--theme-blue .button__content {
  padding: 16px 13px 16px 48px;
}

.button__spinner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  transform: translateY(24px);
  transition:
    200ms opacity,
    200ms transform;
}
.button--loading .button__spinner {
  opacity: 1;
  transform: translateY(0);
}

.button__text {
  z-index: 1;

  flex: 1;
  padding-top: var(--vertical-padding);
  padding-right: var(--text-horizontal-padding);
  padding-bottom: var(--vertical-padding);
  padding-left: var(--text-horizontal-padding);

  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-weight: 600;
  line-height: 100%;
  letter-spacing: 0.02em;

  color: var(--color);
  text-decoration: none;
  text-align: center;
  transition:
    200ms opacity,
    200ms transform;
}

.button--theme-gold .button__text {
  font-size: var(--font-size-sub-title);
}

.button--theme-primary-white .button__text {
  font-weight: 500;
}

.button--loading .button__text {
  opacity: 0;
  transform: translateY(24px);
}

* + .button__text {
  margin-left: var(--gap);
}
@media (--mobile) {
  .button__text {
    font-size: 16px;
  }
}

.button__text::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
  display: block;

  border-radius: var(--border-radius);
}

.button__text strong {
  font-weight: 800;
}

.button__icon {
  --icon-size: 24px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--icon-size);
  height: var(--icon-size);

  transition: 200ms opacity;
}
.button__icon--right {
  --icon-size: 11px;

  flex-shrink: 0;
}

.button--theme-gold .button__icon {
  --icon-size: 8px;
}

.button--theme-blue .button__icon {
  --icon-size: 11px;
  --gap: 29px;
}

* + .button__icon {
  margin-left: var(--gap);
}

@media (max-width: 350px) {
  * + .button__icon {
    margin-left: 2px;
  }
}

.button--loading .button__icon {
  opacity: 0;
}

.button__icon img,
.button__icon svg {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
