.group {
  --js-scroll-offset-top: var(--header-height);

  position: relative;

  overflow: hidden;
}
.group--theme-white {
  background-color: var(--white);
}
.group--theme-black {
  background-color: var(--dark);
}

.group--theme-grey {
  background-color: var(--light-grey);
}

* + .group {
  margin-top: var(--section-gap);
}

.layout__body > .group:first-child {
  margin-top: var(--header-height);
}
.hero-video + .group,
.group + .group {
  margin-top: 0;
}

.group__background {
  position: absolute;
  top: 0;
  left: 0;

  content: '';

  width: 100%;
  height: 100%;
}

.group__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero--size-large + .group,
.group + .group {
  margin-top: 0;
}

.group__body {
  position: relative;
}

* + .group__footer {
  margin-top: var(--section-gap);
}
