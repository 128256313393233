.footer {
  --color: var(--white);
  --logo-height: 93px;

  position: relative;

  padding-top: 120px;
  padding-bottom: 60px;
}
@media (--header-breakpoint) {
  .footer {
    --logo-height: 60px;
    padding-bottom: 32px;
  }
}

@media (--tablet) {
  .footer {
    padding-top: 80px;
  }
}

@media (--mobile) {
  .footer {
    padding-top: 40px;
  }
}
.layout--theme-grey-100 .footer {
  --color: var(--black-text);
}

.footer__background {
  position: absolute;
  top: 0;
  left: 0;

  content: '';

  width: 100%;
  height: 100%;
}

.footer__background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.footer__content {
  display: flex;
  flex-direction: column;

  color: var(--color);
}
* + .footer__content {
  margin-top: 72px;
}
@media (--tablet) {
  * + .footer__content {
    margin-top: 52px;
  }
}
@media (--mobile) {
  * + .footer__content {
    margin-top: 32px;
  }
}

.footer__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (--mobile) {
  .footer__top {
    flex-direction: column;

    align-items: flex-start;
    gap: 32px;
  }
}
.footer__content__useful {
  display: flex;
  flex-direction: column;

  gap: 40px;

  max-width: 383px;
}

.footer__content__useful__logo {
  display: flex;
  height: 100%;
}

.footer__content__useful__logo svg {
  display: block;
  align-items: center;
  width: auto;
  height: var(--logo-height);
  user-select: none;
}

.footer__content__useful__content {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: var(--white);
}

.footer__content__useful__social {
  display: flex;
  align-items: center;
  height: 100%;
  list-style: none;
}

.footer__content__useful__social a:not(:first-of-type) {
  position: relative;

  padding-left: 16px;
}

.footer__content__useful__social a::after {
  position: absolute;

  content: '';
}

.footer__content__columns {
  display: flex;

  gap: 120px;
}

@media (--tablet) {
  .footer__content__columns {
    gap: 80px;
  }
}

@media (--mobile) {
  .footer__content__columns {
    flex-direction: column;
    gap: 32px;

    align-self: baseline;
  }
}

.footer__content__column__links {
  display: flex;
  flex-direction: column;
}

* + .footer__content__column__links {
  margin-top: 32px;
}

.footer__content__column .footer__content__column__title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.footer__content__column .footer__content__column__title h2 {
  font-family: var(--font-family-text);
  font-size: var(--font-size-body);
  font-weight: 600;
  line-height: 150%;
  letter-spacing: 0.07em;
  color: var(--white);
  text-transform: uppercase;
}

.footer__content__column .footer__content__column__title::before {
  content: '';
  display: block;
  width: 2px;
  height: 20px;

  background-color: var(--gold-500);
}

.footer__content__column__description {
  display: flex;
  gap: 16px;
}
.footer__content__column__description
  .footer__content__column__description__content
  p {
  max-width: 30ch;

  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: var(--color);
}

@media (--mobile) {
  .footer__content__column__description
    .footer__content__column__description__content
    p {
    max-width: 100%;
  }
}

.footer__content__column__description + .footer__content__column__description {
  margin-top: 24px;
}

.footer__content__column__description
  .footer__content__column__description__content
  p
  a {
  position: relative;

  color: inherit;
  text-decoration: none;
}
.footer__content__column__description
  .footer__content__column__description__content
  p
  a:hover {
  text-decoration: underline;
}
.footer__content__column__link + .footer__content__column__link {
  margin-top: 24px;
}
.footer__content__column__link a {
  position: relative;

  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: var(--color);
  text-decoration: none;
}

.footer__content__column__link a:hover {
  text-decoration: underline;
}

* + .footer__bottom {
  margin-top: 120px;
}

@media (--tablet) {
  * + .footer__bottom {
    margin-top: 80px;
  }
}

@media (--mobile) {
  * + .footer__bottom {
    margin-top: 40px;
  }
}

.footer__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__bottom__links {
  display: flex;
  gap: 32px;
}

.footer__bottom__links a {
  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 400;
  color: var(--white);
  text-decoration: none;

  text-align: center;
}

.footer__bottom__links a:hover {
  text-decoration: underline;
}

.footer__bottom__copyright {
  width: 100%;
  margin-top: 40px;
  padding-top: 40px;

  font-family: var(--font-family-text);
  font-size: var(--font-size-sub-title);
  font-weight: 400;
  color: var(--white);

  border-top: 1px solid rgba(var(--white-rgb), 0.2);
  text-align: center;
}

.footer__content__column__description__icon {
  position: relative;

  top: 4px;
}
